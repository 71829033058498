import React from "react";

import videoStyles from "./videos.module.css";
import Head from "../components/head";
import { graphql } from "gatsby";

export const query = graphql`
  query {
    allContentfulVideoUploads {
      edges {
        node {
          title
          description {
            description
          }
          youtubeLink
        }
      }
    }
  }
`;

class videos extends React.Component {
  render() {
    return (
      <>
      <Head title="TSS videos"></Head>
        <div className={videoStyles.videoContainer}>
          <h1>Videos</h1>
          {this.props.data.allContentfulVideoUploads.edges.length === 1 &&
          this.props.data.allContentfulVideoUploads.edges[0].node.title ===
            "null" ? (
            <div>
              <h2 style={{ textAlign: "center" }}>
                No Videos uploaded currently.
              </h2>
            </div>
          ) : (
            this.props.data.allContentfulVideoUploads.edges.map(
              (edge, index) => {
                if (edge.node.title !== "null") {
                  let link = edge.node.youtubeLink.split(
                    "youtube.com/watch?v="
                  );
                  return (
                    <div
                      key={index}
                      className={videoStyles.videoPlayerContainer}
                    >
                      <h3>{edge.node.title}</h3>
                      <p>{edge.node.description.description}</p>
                      <div>
                        <iframe
                          className={videoStyles.videoAttr}
                          src={`https://www.youtube.com/embed/${link[1]}`}
                          frameborder="0"
                          allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  );
                }
              }
            )
          )}
        </div>
      </>
    );
  }
}
export default videos;
